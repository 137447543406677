@tailwind base;
@tailwind components;
@tailwind utilities;

// @layer utilities {
//   .btn-transition {
//     @apply ease-in duration-100;
//   }
//   .label {
//     @apply text-base font-bold leading-6 mb-2 inline-block;
//   }
//   .opening-hour-wrapper {
//     @apply flex items-baseline sm:items-center sm:flex-row mb-6 sm:mb-4 sm:space-x-8 min-h-[40px];
//   }
//   .radio-top-right {
//     @apply absolute -right-0.5 -top-0.5;
//   }
// }
